<template>
  <crud-form
    :schema="schema"
    :model-id="id"
    :path="'article'"
    :form-title="$t('updateArticle')"
    :redirect-route="'ArticlesList'"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './schema/article';

  export default {
    name: 'UpdateArticle',
    props: ['id'],
    components: {
      CrudForm
    },
    data: () => {
      return {
        schema,
      }
    },
  }
</script>
